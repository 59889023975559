import styled, {keyframes, css} from 'styled-components';
import Theme from '../../theme';
import {FeaturePosition, KeyFeaturesView} from './KeyFeatures.types';

export const PluginColours = {
  white: '#ffffff',
  primary: '#02d642',
  secondary: '#a7312d',
  greys: [
    '#d3d3d3',
    '#d8d8d8',
    '#808080',
    '#5e5e5e',
    '#555555',
    '#3c3c3c',
    '#2d2d2d',
    '#1a1a1a',
  ],
};

export const PLUGIN_WIDTH = 1150;
export const PLUGIN_HEIGHT = 577;

export const Plugin = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 15.5% 84.5%;
  grid-template-rows: 14% 80% 6%;
  width: 100%;

  aspect-ratio: ${PLUGIN_WIDTH} / ${PLUGIN_HEIGHT};
  margin: auto;
  background-color: ${PluginColours.greys[6]};
  box-shadow: 0px 7px 40px rgba(21, 22, 22, 0.6);
  font-size: 15px;

  @media (max-width: ${Theme.breakpoints[3]}px) {
    width: 95%;
  }

  @media (max-width: ${Theme.breakpoints[2]}px) {
    display: none;
  }
`;

export const PluginHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column: 1 / 3;
  grid-row: 1 / 1;
  align-self: center;
  padding-right: 2%;
`;

export const PluginMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PluginLogo = styled.svg`
  margin-left: 2%;
  margin-right: auto;
`;

export const ChoosePreset = styled.div`
  color: ${PluginColours.greys[4]};
  margin-right: 6%;
`;

export const Save = styled.div`
  margin-right: 2%;
`;

export const GearIcon = styled.svg`
  width: 25px;
  height: 25px;
  margin-right: 5%;

  path {
    fill: ${PluginColours.greys[0]};
  }
`;

export const Bypass = styled.div`
  margin-right: 2%;
`;

export const BackArrow = styled.svg`
  path {
    fill: ${PluginColours.greys[0]};
  }
`;

export const ForwardArrow = styled.svg`
  margin-right: 5%;
  path {
    fill: ${PluginColours.greys[4]};
  }
`;

export const Focusrite = styled.svg`
  margin-right: 3%;
  path {
    fill: ${PluginColours.white};
  }
`;

export const Relearn = styled.div`
  text-align: center;
  line-height: 25px;
  align-self: center;
  width: 75%;
  height: 25px;
  border-radius: 2px;
  font-size: 15px;
  background-color: ${PluginColours.greys[4]};
`;

export const Profile = styled(Relearn)`
  margin-top: 8%;
`;

export const ProfileLabel = styled.p`
  font-size: 14px;
`;

export const Weight = styled.div`
  margin-top: 10%;
  border: 2px solid ${PluginColours.greys[4]};
  border-radius: 6px;
  width: 75%;
  text-align: center;
  font-size: 14px;
`;

interface WeightOptionProps {
  selected?: boolean;
}

export const WeightOption = styled.div<WeightOptionProps>`
  ${({selected = false}) => css`
    font-size: ${selected ? '15px' : '14px'};
    background-color: ${selected
      ? PluginColours.greys[4]
      : Theme.colours.transparent};
  `}
`;

export const DuckingControl = styled.div`
  position: relative;
  width: 10px;
  height: 156px;
  border-radius: 2px;
  margin-top: 20%;
  justify-self: end;
  background-color: ${PluginColours.greys[5]};
`;

export const DuckingInner = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 10px;
  height: 100%;
  background-color: ${PluginColours.primary};
  border-radius: 2px;
  transform: scaleY(0.5);
  transform-origin: bottom left;
  transition: transform 0.3s;
`;

export const DuckingLabel = styled.div`
  text-align: center;

  p {
    line-height: 1;
    padding-top: 10px;
    font-size: 14px;
  }

  p:first-child {
    padding-top: 15px;
  }
`;

interface PluginContentProps {
  view: KeyFeaturesView;
}

export const PluginContent = styled.div<PluginContentProps>`
  ${({view}) => css`
    grid-colum: 2 / 1;
    background-image: ${view === KeyFeaturesView.FAST
      ? "url('/images/reveal-ui.png')"
      : "url('/images/reveal-ui-detailed.png')"};
    background-repeat: no-repeat;
    background-size: contain;
  `}
`;

const pulseDot = keyframes`
  0% {
    transform:scale(0.8);
  }

  70% {
    transform:scale(1.1);
  }

  100% {
    transform:scale(0.8);
  }
`;

const pulseRing = keyframes`
  0% {
    transform:translate(-25%, -25%) scale(0);
    opacity: 0;
  }

  50% {
    transform:translate(-25%, -25%) scale(0);
    opacity: 1;
  }

  100% {
    transform:translate(-25%, -25%) scale(1.1);
    opacity: 0;
  }
`;

interface PointProps {
  x: number;
  y: number;
  delay?: number;
}

export const Point = styled.div<PointProps>`
  ${({x, y, delay = 0}) =>
    css`
      position: absolute;
      top: ${y}%;
      left: ${x}%;
      width: 25px;
      height: 25px;
      cursor: pointer;
      transform: translate(-50%, -50%);

      &:before {
        content: '';
        display: block;
        position: relative;
        border-radius: 50%;
        background-color: ${PluginColours.white}CC;
        width: 50px;
        height: 50px;
        animation: ${pulseRing} 1.8s ease-out infinite;
        animation-delay: ${delay}ms;
        opacity: 0;
        transition: background-color 0.3s;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        background-color: ${PluginColours.white}CC;
        border-radius: 50%;
        animation: ${pulseDot} 1.8s ease-in-out infinite;
        animation-delay: ${delay}ms;
        transform-origin: center;
        transition: background-color 0.3s;
      }

      &:hover:after {
        background-color: ${Theme.colours.blue};
      }
    `}
`;

interface FeatureProps {
  show: boolean;
  position?: FeaturePosition;
}

export const Feature = styled.div<FeatureProps>`
  ${({show = false, position = FeaturePosition.RIGHT}) => css`
    position: absolute;
    top: 0px;
    left: ${position === FeaturePosition.RIGHT
      ? '45px'
      : 'calc(-250px + -30px)'};
    width: max-content;
    max-width: 250px;
    line-height: 1.4;
    font-weight: 400;
    background-color: ${PluginColours.white};
    color: ${Theme.colours.greys[8]};
    padding: 0.8rem 1rem;
    border-radius: 6px;
    opacity: ${show ? 0.95 : 0};
    transition: opacity 0.3s, transform 0.3s;
    transform: ${show
      ? 'translate(0px, calc(-50% + 12px))'
      : 'translate(-20px, calc(-50% + 12px))'};
    pointer-events: none;
  `}
`;

interface FeatureTriangleProps {
  position: FeaturePosition;
}

export const FeatureTriangle = styled.div<FeatureTriangleProps>`
  ${({position}) => css`
    position: absolute;
    top: calc(50% - 10px);
    ${position === FeaturePosition.RIGHT && `left: -10px`};
    ${position === FeaturePosition.LEFT && `right: -10px`};
    ${position === FeaturePosition.RIGHT && `border-right: 10px solid white`};
    ${position === FeaturePosition.LEFT && `border-left: 10px solid white`};
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  `}
`;

export const DuckingHandle = styled.div`
  position: absolute;
  top: calc(50% - 4px);
  left: -4px;
  width: 18px;
  height: 8px;
  background-color: white;
  border-radius: 2px;
  z-index: 200;
  box-shadow: 1px 1px 1px ${Theme.colours.darkGrey};
`;
