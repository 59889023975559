import React, {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {KeyFeaturesReveal} from '../../components/KeyFeatures';
import {KeyFeaturesView} from '../../components/KeyFeatures/KeyFeatures.types';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import {GutterMaxWidth, Gutters} from '../../components/Spacing/Gutters';
import {Spacer} from '../../components/Spacing/Spacer';
import Theme from '../../theme';
import {FooterContent} from '../../types/FooterContent';
import {Point} from '../../types/ProductV2';

export const KeyFeaturesSection = styled.section`
  background-color: ${Theme.colours.greys[8]};
  color: ${Theme.colours.white};

  h3 {
    font-family: Montserrat;

    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    width: 100%;

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #e2e5e6;
  }

  @media (max-width: ${Theme.breakpoints[2]}px) {
    display: none;
  }
`;

interface ProductListPageProps {
  pageContext: {
    contentfulFooterContent: FooterContent;
    customerPortalBaseUrl: string;
  };
}

interface Points {
  fast: Point[];
  detailed: Point[];
}

const points: Points = {
  fast: [],
  detailed: [],
};

const KeyFeaturesPage: FunctionComponent<ProductListPageProps> = ({
  pageContext: {contentfulFooterContent, customerPortalBaseUrl},
}) => {
  const [view, setView] = useState<KeyFeaturesView>(KeyFeaturesView.FAST);

  return (
    <Layout
      {...contentfulFooterContent}
      customerPortalBaseUrl={customerPortalBaseUrl}
    >
      <SEO title="Key Features" />
      <KeyFeaturesSection>
        <Gutters maxWidth={GutterMaxWidth.LARGE}>
          <Spacer />
          <Spacer />
          <KeyFeaturesReveal points={points[view]} view={view} />
          <Spacer />
          <Spacer />
        </Gutters>
      </KeyFeaturesSection>
    </Layout>
  );
};

export default KeyFeaturesPage;
